import './App.css';
import BgMountains from './assets/images/Mountain-01.png'
// import BgMountainsWebp from './assets/images/Mountain-01.webp'
import Land from './assets/images/Land-01.png'
// import LandWebp from './assets/images/Land-01.webp'
// import OgreCaveWeb from './assets/images/Cave.webp'
import OgreCave from './assets/images/Caves.png'
import Rocks from './assets/images/3-rock-01.png'
// import RocksWebp from './assets/images/3-rock-01.webp'
import Ogre from './assets/images/Ogre.png'
// import OgreWeb from './assets/images/Ogres.webp'
import OgrestownLogo from './assets/images/Ogrestown-Logo-01.png'
// import OgrestownLogoWeb from './assets/images/Ogrestown-Logo-01.webp'
import Bats from './assets/images/bats.png'
import Sky from './assets/images/Sky-01.png'
// import SkyWebp from './assets/images/Sky-01.webp'
import CloudsClub from './assets/images/Clouds.png'
// import CloudsWebp from './assets/images/CloudWebp.webp'
import Fog from './assets/images/Fog-Ground-01.png'
// import FogWebp from './assets/images/Fog-Ground-01.webp'
import UpperFog from './assets/images/Upper-Fog-01.png'
// import UpperFogWebp from './assets/images/Upper-Fog-01.webp'
import { useState, useEffect } from 'react';
// import Loader from './components/loader/loader';
import MblMountain from './assets/images/mbl-mountains.png';
function App() {
  const [coord, setCoord] = useState({ x: 0, y: 0 });
  const getPercentage = (amount, total) => amount * 100/total;
  const width = window.innerWidth;
  const height = window.innerHeight;
  const handleMouseMove = (e) => {
    setCoord({ x: e.screenX, y: e.screenY });
  };
  const logoTop = 20 - getPercentage(coord.y, height) * (-0.02);
  const logoLeft = 23 - getPercentage(coord.x, width) * 0.02;
  const ogreTop = 57 - getPercentage(coord.y, height) * 0.04;
  const ogreLeft = 38 - getPercentage(coord.x, width) * (-0.01);
  // const [ spinner, setSpinner ] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => setSpinner(false), 3000)
  // }, []);
  return (
      <>
        {/* {spinner ? <Loader/> :  */}
        <div id="scene" className="main" onMouseMove={handleMouseMove}>
          <div  className="mountains-layer">
            {/* <img srcset={BgMountains}
              sizes="(max-width: 600px) 480px, 800px"
              src={MblMountain}
              alt="mountains" /> */}
            <img src={MblMountain} alt="mountains" className='mbl-view' />
            <img src={BgMountains} alt="mountains" className='web-view'/>
            {/* <picture>
              <source srcSet={BgMountainsWebp} type="image/webp"/>
              <source srcSet={BgMountains} type="image/png"/>
              <img src={BgMountains} alt="my image"/>
            </picture> */}
          </div>
          <div className="land-layer">
            <img src={Land} alt="Land" />
            {/* <picture>
              <source srcSet={LandWebp} type="image/webp"/>
              <source srcSet={Land} type="image/png"/>
              <img src={Land} alt="my image"/>
            </picture> */}
          </div>
          <div className="ogrecave-layer">
            <img src={OgreCave} alt="ogrecave" />
            {/* <picture>
              <source srcSet={OgreCaveWeb} type="image/webp"/>
              <source srcSet={OgreCave} type="image/png"/>
              <img src={OgreCave} alt="my image"/>
            </picture> */}
          </div>
          <div className="rocks-layer">
            <img src={Rocks} alt="rocks" />
            {/* <picture>
              <source srcSet={RocksWebp} type="image/webp"/>
              <source srcSet={Rocks} type="image/png"/>
              <img src={Rocks} alt="my image"/>
            </picture> */}
          </div>
          <div style={{right:`${ogreLeft}%`, top:`${ogreTop}%`}} className="ogre-layer web-view">
            <img src={Ogre} alt="ogre" />
            {/* <picture>
              <source srcSet={OgreWeb} type="image/webp"/>
              <source srcSet={Ogre} type="image/png"/>
              <img src={Ogre} alt="my image"/>
            </picture> */}
          </div>
          <div className="ogre-layer mbl-view">
          <img src={Ogre} alt="my image"/>
            {/* <picture>
              <source srcSet={OgreWeb} type="image/webp"/>
              <source srcSet={Ogre} type="image/png"/>
              <img src={Ogre} alt="my image"/>
            </picture> */}
          </div>
          <div style={{right:`${logoLeft}%`, top:`${logoTop}%`}} className="ogreslogo-layer web-view">
          <img src={OgrestownLogo} alt="my image"/>
            {/* <picture>
              <source srcSet={OgrestownLogoWeb} type="image/webp"/>
              <source srcSet={OgrestownLogo} type="image/png"/>
              <img src={OgrestownLogo} alt="my image"/>
            </picture> */}
          </div>
          <div className="ogreslogo-layer mbl-view">
          <img src={OgrestownLogo} alt="my image"/>
            {/* <picture>
              <source srcSet={OgrestownLogoWeb} type="image/webp"/>
              <source srcSet={OgrestownLogo} type="image/png"/>
              <img src={OgrestownLogo} alt="my image"/>
            </picture> */}
          </div>
          <div className="bats-layer">
            <img src={Bats} alt="bats" />
          </div>
          <div className="sky-layer">
            <img src={Sky} alt="sky" />
            {/* <picture>
              <source srcSet={SkyWebp} type="image/webp"/>
              <source srcSet={Sky} type="image/png"/>
              <img src={Sky} alt="my image"/>
            </picture> */}
          </div>
          <div className="cloudclub-layer">
            <img src={CloudsClub} alt="clouds" />
            {/* <picture>
              <source srcSet={CloudsWebp} type="image/webp"/>
              <source srcSet={CloudsClub} type="image/png"/>
              <img src={CloudsClub} alt="my image"/>
            </picture> */}
          </div>
          <div className="fog-layer">
            <img src={Fog} alt="fog" />
            {/* <picture>
              <source srcSet={FogWebp} type="image/webp"/>
              <source srcSet={Fog} type="image/png"/>
              <img src={Fog} alt="my image"/>
            </picture> */}
          </div>
          <div className="upperfog-layer">
            <img src={UpperFog} alt="UpperFog" />
            {/* <picture>
              <source srcSet={UpperFogWebp} type="image/webp"/>
              <source srcSet={UpperFog} type="image/png"/>
              <img src={UpperFog} alt="my image"/>
            </picture> */}
          </div>
        </div>
        {/* } */}
      </>
  );
}
export default App;